import * as React from 'react';

export default function OtherLinks() {
    const subPages = [
        { name: 'Legends Ultimate', path: '/arcades' },
        { name: 'AZF', path: 'https://www.atgames.us/' },
        { name: 'E-Store', path: 'https://www.atgames.us/' },
        { name: 'Blast', path: 'https://blast.atgames.net/' },
        { name: 'ADS', path: '/ads' },
        { name: 'ArcadeNet', path: '/arcadenet' },
    ];
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleOpenURL = (websiteUrl: string | URL | undefined) => {
        setIsMenuOpen(false);
        window.open(websiteUrl, '_self');
    };

    return (
        <div className="relative z-50">
            <button
                onClick={handleToggleMenu}
                className="w-full text-white px-3 py-2 rounded-md text-base font-medium hover:bg-[#bada55] hover:bg-opacity-50 font-semibold"
            >
                Other Links
            </button>
            {isMenuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-gray-900 rounded-md shadow-lg z-50">
                    {subPages.map((subpage) => (
                        <button
                            key={subpage.name}
                            onClick={() => handleOpenURL(subpage.path)}
                            className="block w-full text-left px-4 py-2 bg-gray-900 text-sm text-gray-100 hover:bg-gray-700"
                        >
                            {subpage.name}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}