import { Link } from 'react-router-dom';
import 'tailwindcss/tailwind.css';

function createData(
  name: string,
  aoi: string,
  location: string,
  href: string,
) {
  return { name, aoi, location, href };
}

const rows = [
  createData('Data Analyst (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/data-analyst'),
  createData('Marketing Manager (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/marketing-manager'),
  createData('Senior Web Front-end Developer (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/senior-web-frontend-developer'),
  createData('Live Operations Manager (Local Candidates Only)', 'Operations', 'El\u00A0Segundo, CA', '/career/liveops-manager'),
  createData('Assistant Games Merchandiser (Local Candidates Only)', 'Merchandising', 'El\u00A0Segundo, CA', '/career/assistant-game-merchandiser'),
  createData('Marketing Assistant Sales and Marketing Coordinator (Local Candidates Only)', 'Sales and Marketing', 'El\u00A0Segundo, CA', '/career/marketing-assistant-sales-and-marketing-coordinator'),
  createData('System Administrator - Contract (Local Candidates Only)', 'System', 'El\u00A0Segundo, CA', '/career/system-administrator-contract'),
]

export default function BasicTable() {
  return (
    <div className="container mx-auto mt-4 p-4">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b">Role</th>
              <th className="py-2 px-4 border-b text-right">Area of Interest</th>
              <th className="py-2 px-4 border-b text-right">Location</th>
              <th className="py-2 px-4 border-b text-right">Apply</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.name} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b">
                  <Link to={row.href} className="text-blue-600 hover:underline">{row.name}</Link>
                </td>
                <td className="py-2 px-4 border-b text-right">{row.aoi}</td>
                <td className="py-2 px-4 border-b text-right">{row.location}</td>
                <td className="py-2 px-4 border-b text-right">
                  <Link to={'mailto:career@atgames.net'}>
                    <button className="bg-[#bada55] text-white py-1 px-3 rounded hover:bg-green-800 font-semibold">
                      Apply
                    </button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}