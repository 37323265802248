import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { atgamesLogoLong } from '../assets';
import OtherLinks from './OtherLinks';

const ResponsiveAppBar = () => {
  const pages = [
    { name: 'Home', path: '/' },
    { name: 'About', path: '/about' },
    /*{ name: 'Press', path: '/press' },*/
    { name: 'Career', path: '/career' },
  ];

  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const location = useLocation();

  const handleOpenNavMenu = () => {
    setIsNavOpen(true);
  };

  const handleCloseNavMenu = () => {
    setIsNavOpen(false);
  };

  return (
    <nav className="bg-black bg-opacity-80 shadow-md z-40">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <a href="/" className="hidden md:flex mr-2 ml-2 h-8 items-center">
              <img src={atgamesLogoLong} alt="atgamesLogo" className="h-6 w-auto" />
            </a>
            <a href="/" className="md:hidden">
              <img src={atgamesLogoLong} alt="atgamesLogo" className="h-8 transition-transform duration-300 hover:scale-110" />
            </a>
          </div>
          <div className="flex md:hidden">
            <button
              onClick={handleOpenNavMenu}
              className="text-white focus:outline-none"
            >
              <svg className="h-6 w-6 transition-transform duration-300 transform hover:scale-110" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
          <div className={`fixed inset-0 bg-black bg-opacity-50 z-30 ${isNavOpen ? 'block' : 'hidden'}`} onClick={handleCloseNavMenu}></div>
          <div className={`fixed text-black bg-black top-0 right-0 w-64 h-full z-40 transform ${isNavOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:relative md:flex md:translate-x-0 md:bg-transparent md:h-auto md:w-auto`}>
            <div className="flex flex-col md:flex-row md:ml-auto md:mr-10">
              {pages.map((page) => (
                <Link
                  key={page.name}
                  to={page.path}
                  onClick={handleCloseNavMenu}
                  className={`block px-3 py-2 rounded-md text-base font-semibold ${location.pathname === page.path ? 'bg-[#bada55] hover:text-white' : 'text-white md:text-white'} hover:bg-[#bada55] hover:bg-opacity-50 mr-1`}
                >
                  {page.name}
                </Link>
              ))}
              <OtherLinks />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default ResponsiveAppBar;