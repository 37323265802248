import { Box, Divider, List, Typography } from '@mui/material'
import { atgamesLogoLongBlack, map } from '../../assets'
import WhiteList from '../../components/WhiteList'

const SeniorWebFE = () => {
    return (
        <Box sx={{ height: "100%", overflow: 'auto' }}>
            <Box
                sx={{
                    height: 'auto',
                    width: { xs: '90%', md: '50%' },
                    justifyContent: 'center',
                    backgroundColor: 'rgba(186, 218, 85, 0.7)',
                    margin: 'auto',
                    mt: 5,
                    overflow: 'auto',
                    mb: 16,
                }}
                className={'rounded-lg shadow-lg backdrop-blur-sm'}
            >
                <Box
                    component="img"
                    src={map} alt="atgamesLogo"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        width: '100%',
                        height: 'auto',
                        position: 'relative',
                        mb: 2
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={atgamesLogoLongBlack} className='h-10 w-auto' alt="AtGames Logo" />
                </Box>
                <Typography variant='h1' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 24, md: 32 } }}>
                    Senior Web Frontend Developer
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    Location: El Segundo, California, United States
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    About Us
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    AtGames (www.atgames.net) is an international video game products company that combines over a decade of experience with innovative ideas and groundbreaking streaming technology.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Job Description
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    We are looking for a senior web front-end developer to build UI/UX for an exciting new web-based project.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Responsibilities
                </Typography>
                <List sx={{ mx: 4 }}>
                    <WhiteList text="Design, build, and maintain efficient, reusable, reliable, and responsive customer-facing web applications" />
                    <WhiteList text="Integrate web services APIs developed by back-end developers and 3rd party vendors" />
                    <WhiteList text="Identify bottlenecks and bugs, and devise solutions to these problems" />
                    <WhiteList text="Write well-designed, testable, efficient code by using the best software development practices" />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Qualifications and Skills
                </Typography>
                <List sx={{ mx: 4 }}>
                    <WhiteList text="Familiar with Node.js, Angular, React, and Electron" />
                    <WhiteList text="Expert-level understanding of CSS and ability to write clean, readable JavaScript code" />
                    <WhiteList text="Ability to translate the design requirements from web designer to actual code that will produce the desired user experience accurately." />
                    <WhiteList text="Able to implement automated testing platforms and unit tests" />
                    <WhiteList text="Proficient understanding of code versioning tools (e.g., Git) and familiarity with continuous integration (e.g., Jenkins)" />
                    <WhiteList text="Solid experience in dealing with all cross-browser compatibility and mobile support issues and building creative solutions to overcome them" />
                    <WhiteList text="Extensive experience in web performance optimization" />
                    <WhiteList text="5+ years’ experience as a senior front-end developer" />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Job Type
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    Full-time
                </Typography>
                <Divider sx={{ margin: 4 }} color='white' />
            </Box>
        </Box>
    )
}

export default SeniorWebFE