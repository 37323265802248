import { Divider, Typography } from '@mui/material'
import { atgamesLogoLongBlack, map } from '../assets'
import RolesTable from '../components/RolesTable'
import 'tailwindcss/tailwind.css'

const Career = () => {
  return (
    <div className="h-full overflow-auto">
      <div className="container md:w-1/2 mx-auto mt-5 p-4 bg-[#bada55] bg-opacity-70 overflow-auto flex flex-col items-center mb-16 md:mb-48 rounded-lg shadow-lg backdrop-blur-sm">
        {/*<img src={map} className='responsive-img'/>*/}
        <img
          src={map}
          alt="atgamesLogo"
          className="hidden md:block w-full h-auto mb-2"
        />
        <img src={atgamesLogoLongBlack} alt="AtGames Logo" className='h-12 mx-auto my-4' />
        <Typography className="text-white font-medium my-4 text-center text-sm md:text-base lg:text-lg">
          AtGames®, founded in 2001, is a veteran player in digital media and information technology with a
          unique focus on interactive entertainment. Over the years, our achievements have spanned retail
          products, digital distribution, streaming technology, and more.
          <br /><br />

          With locations in Los Angeles, Taipei, and Shenzhen, our globe-spanning office branches synergize and
          evolve to produce products and services that stand out from the rest. This distinctive spirit is embodied
          in our latest hit product, the Legends Ultimate, a full-size home arcade platform with innovative
          connected features.
          <br /><br />

          <b>AtGames of America, Inc. (El Segundo, CA)</b>
          <br /><br />

          <b>Product Management, Product Marketing, Technical Roles</b>
          <br /><br />

          AtGames (<a href='https://www.atgames.net/' className='font-semibold'>https://atgames.net</a>) is looking for talented individuals to join the team at our El
          Segundo headquarters to help drive the development, marketing, and sales of the next generation of
          retro console, portable, and connected home arcade products. As a valued team member, you will use
          your background in consumer products and games hardware, product management, and/or technical
          expertise informed by a Computer Science or Electrical & Computer Engineering degree to succeed in
          this fun, fast-paced environment.<br />
          <br />

          If you feel your background is a fit, are up for the challenge, and have a passion for cutting edge home<br />
          consumer products with retro appeal, please send your resume or CV to <a href='mailto:career@atgames.net' className='font-semibold'>career@atgames.net</a>.<br />
        </Typography>
        <RolesTable />
        <Divider className="my-4" color='white' />
        <Typography className="text-white font-medium mx-4 text-center text-sm md:text-base lg:text-lg">
          This document is proprietary and confidential. No part of this document may be disclosed in any manner to a third party without the prior written consent of AtGames.
        </Typography>
      </div>
    </div>
  )
}

export default Career