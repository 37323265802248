import { Box, Divider, List, Typography } from '@mui/material'
import { atgamesLogoLongBlack, map } from '../../assets'
import WhiteList from '../../components/WhiteList'

const SystemAdministrator = () => {
    return (
        <Box sx={{ height: "100%", overflow: 'auto' }}>
            <Box
                sx={{
                    height: 'auto',
                    width: { xs: '90%', md: '50%' },
                    justifyContent: 'center',
                    backgroundColor: 'rgba(186, 218, 85, 0.7)',
                    margin: 'auto',
                    mt: 5,
                    overflow: 'auto',
                    mb: 16,
                }}
                className={'rounded-lg shadow-lg backdrop-blur-sm'}
            >
                <Box
                    component="img"
                    src={map} alt="atgamesLogo"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        width: '100%',
                        height: 'auto',
                        position: 'relative',
                        mb: 2
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={atgamesLogoLongBlack} className='h-10 w-auto' alt="AtGames Logo" />
                </Box>
                <Typography variant='h1' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 24, md: 32 } }}>
                    System Administrator - Contract
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    Location: El Segundo, California, United States
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    About Us
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    AtGames Digital Media is an international video game distribution company that combines over a decade of experience with innovative ideas and groundbreaking streaming technology. We are building upon our years of success to bring seamless new web experiences to gamers worldwide. We are currently looking for a Contract System Administrator to join our team.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Job Description
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    AtGames Interactive (http://www.atgames.net), a leader in innovative consumer-oriented interactive entertainment products, is looking for a passionate Data Analyst. The successful candidate will turn data into information, information into insight, and insight into actionable business decisions.
                    <br />
                    This position is 100% remote.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Responsibilities
                </Typography>
                <List sx={{ mx: 4 }}>
                    <WhiteList text="Maintaining and administering production servers and related computing environments including hardware, servers, systems software, application software and all configurations." />
                    <WhiteList text="Performing data backups and data recovery operations." />
                    <WhiteList text="Diagnoses, troubleshoots, and resolves hardware/software and other network/system problems, replaces defective components when necessary." />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Qualifications and Skills
                </Typography>
                <List sx={{ mx: 4 }}>
                    <WhiteList text="B.S. in computer science or related field" />
                    <WhiteList text="2-3 years System/network engineering experience" />
                    <WhiteList text="Advanced knowledge of Ubuntu and Windows including software management and deployment" />
                    <WhiteList text="Advanced knowledge of and experience with networking concepts and network implementations including routing, VLANs, firewalls etc." />
                    <WhiteList text="Experience in large-scale server provisioning and deployment automation process - ex. Using Salt or other IT automation tools to handle system deployment and update process" />
                    <WhiteList text="Experience in using cloud-computing platforms (AWS, Google, etc.)" />
                    <WhiteList text="Experience in web development or video streaming development" />
                    <WhiteList text="Team player attitude" />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Job Type
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    Full-time
                </Typography>
                <Divider sx={{ margin: 4 }} color='white' />
            </Box>
        </Box>
    )
}

export default SystemAdministrator