import { Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { adscomputer, adslogo, atgamesLogo, fungame } from '../assets'
import { Link } from 'react-router-dom'
import StartIcon from '@mui/icons-material/Start';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const ADS = () => {
    return (
        <Box
            component={'body'}
            className='adsBG'
            sx={{
                height: '100%',
                width: 1,
                justifyContent: 'center',
                backgroundColor: 'rgba(186, 218, 85, 0.7)',
                border: 2,
                borderColor: 'black',
                mt: 0,
                overflow: 'auto',
                top: 0,
                left: 0,
            }}
        >
            <Box
                component={'img'}
                src={adslogo} alt="Atgames Digital Services Logo"
                sx={{ width: 200, height: 'auto', mt: 4, mr: 'auto', ml: 'auto' }}
            />
            <Typography variant='h1' sx={{ color: 'white', fontWeight: 400, mt: 4, mr: 'auto', ml: 'auto', fontSize: 24, width: .3 }}>
                AtGames Digital Services
                <br />
                Brings pioneering synergy between online and physical storefronts unique for digital game distribution.
            </Typography>
            <Button
                variant="contained"
                color='success'
                component={Link}
                to={'https://pp.ads.atgames.net/login?returnUrl=~2F'}
                sx={{
                    backgroundColor: 'rgba(130, 162, 29, 0.7)',
                    border: 1,
                    borderColor: 'black',
                    mt: 5
                }}
            >Ads Partner Portal <StartIcon sx={{ ml: 1 }} />
            </Button>
            <Card sx={{ display: 'flex', height: 'auto', mt: 15 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: .8, backgroundColor: '#bada55' }}>
                    <CardContent sx={{}}>
                        <Typography component="div" variant="h2" sx={{ color: 'white', fontWeight: 400, mt: 10, mr: 'auto', ml: 'auto', fontSize: 32, width: .3 }}>
                            WHAT WE DO
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary" component="div" sx={{ width: .6, ml: 'auto', mr: 'auto', mt: 4 }}>
                            AtGames Digital Services partners with online retailers to allow them to sell our catalog of digital games to their own customer base. Integration with the ADS platform requires minimal effort. Through our Partner Portal, our Partners can easily manage their game assortment, set and manage promotions, and view sales and financial information.
                            The retailers work with us on merchandising and promotions to maximize revenue opportunities.
                            <br /><br />
                            We are dedicated to offering our Partners the newest digital games. ADS works with leading publishers to distribute their titles to our retailers.
                        </Typography>
                    </CardContent>
                </Box>
                <CardMedia
                    component="img"
                    sx={{ width: '60%', height: 'auto', display: { xs: 'none', sm: 'block' }, alignSelf: 'center' }}
                    image={adscomputer}
                    alt="Atgames Digital Services Dashboard"
                    className='responsive-img'
                />
            </Card>
            <Box
                component={'body'}
                className='adsBG2'
                sx={{
                    height: '100%',
                    width: 1,
                    justifyContent: 'center',
                    backgroundColor: 'rgba(186, 218, 85, 0.7)',
                    border: 2,
                    borderColor: 'black',
                    mt: 0,
                    overflow: 'auto',
                    top: 0,
                    left: 0,
                }}
            >
                <Typography variant='h3' sx={{ color: 'white', fontWeight: 400, mt: 8, mr: 'auto', ml: 'auto', fontSize: 24, width: .3 }}>
                    PARTNERS
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 400, mt: 4, mr: 'auto', ml: 'auto', fontSize: 18, width: .3 }}>
                    Building on trusted relationships publishers have with AtGames,
                    <br /><br />
                    ADS continues to foster great partnerships with new and long-standing publishers.
                </Typography>
                <Card sx={{ display: 'flex', height: 'auto', mt: 8 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: .4, backgroundColor: '#bada55' }}>
                        <CardMedia
                            component="img"
                            sx={{ width: '120%', height: 'auto', display: { xs: 'none', sm: 'block' }, alignSelf: 'center', overflow: 'cut' }}
                            image={fungame}
                            alt="Fun Game"
                            className='responsive-img'
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: .4, backgroundColor: 'black' }}>
                        <CardContent sx={{}}>
                            <Typography component="div" variant="h2" sx={{ color: 'white', fontWeight: 400, mt: 3, mr: 'auto', ml: 'auto', fontSize: 32, width: .3 }}>
                                ABOUT ATGAMES
                            </Typography>
                            <Typography variant="subtitle1" component="div" sx={{ width: .6, ml: 'auto', mr: 'auto', mt: 4, color: 'white' }}>
                                AtGames develops innovative interactive entertainment products for worldwide distribution.
                                Founded in 2001 by veterans in digital media and information technology industries.
                                AtGames sells and distributes various products in North America, Latin America, Europe, C.I.S., Australia, and Asia.
                            </Typography>
                        </CardContent>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: .4, backgroundColor: 'white' }}>
                        <CardContent sx={{}}>
                            <Typography component="div" variant="h2" sx={{ color: 'black', fontWeight: 400, mt: 3, mr: 'auto', ml: 'auto', fontSize: 32, width: .3 }}>
                                CONTACT US
                            </Typography>
                            <ContactMailIcon sx={{ color: 'black', fontSize: 32, mt: 3, mr: 'auto', ml: 'auto' }} />
                            <Typography variant="subtitle1" component="div" sx={{ width: .6, ml: 'auto', mr: 'auto', color: 'black' }}>
                                <Box component={Link} to={'mailto:info@atgames.net'} className='aLinkBlack'>info@atgames.net</Box>
                            </Typography>
                            <Box component={'img'} src={atgamesLogo} sx={{ color: 'black', width: 45, height: 'auto', mt: 3, mr: 'auto', ml: 'auto' }} />
                            <Typography variant="subtitle1" component="div" sx={{ width: .6, ml: 'auto', mr: 'auto', color: 'black' }}>
                                <Box component={Link} to={'https://atgames.net'} className='aLinkBlack'>www.atgames.net</Box>
                            </Typography>
                        </CardContent>
                    </Box>
                </Card>
                <Box
                    component={'body'}
                    className='adsBG3'
                    sx={{
                        width: 1,
                        height: .25,
                        justifyContent: 'center',
                        backgroundColor: '#000000',
                        mt: 0,
                        overflow: 'auto',
                        top: 0,
                        left: 0,
                    }}
                >
                    <Typography sx={{ color: 'white', fontFamily: 'monospace', fontWeight: 300, letterSpacing: '.1rem', textAlign: 'center', pb: 5, mt: 4 }}>
                        Copyright © AtGames Cloud Holdings {new Date().getFullYear()}. All rights reserved<br />
                        The trademarks and images are the property of their respective owners. Used for demonstration purposes only.
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default ADS