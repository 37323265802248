import { Image1, Image2, Image4, Image5, Image7, Image9 } from '../assets'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'


function srcset(image: string, maxWidth: number, maxHeight: number, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${maxWidth * cols}&h=${maxHeight * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${maxWidth * cols}&h=${maxHeight * rows}&fit=crop&auto=format&dpr=2 2x`,
    }
}

const useResponsiveColumns = (breakpoints: { [key: string]: number }) => {
    const getColumns = (width: number) => {
        if (width < breakpoints.sm) return 1
        if (width < breakpoints.md) return 2
        if (width < breakpoints.lg) return 3
        if (width < breakpoints.xl) return 5
        return 5
    }

    const [columns, setColumns] = useState(getColumns(window.innerWidth))

    useEffect(() => {
        const updateDimensions = () => setColumns(getColumns(window.innerWidth))
        window.addEventListener("resize", updateDimensions)
        return () => window.removeEventListener("resize", updateDimensions)
        // eslint-disable-next-line
    }, [])

    return columns
}

const HomeImageList = () => {
    const breakpoints = { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 }
    const columns = useResponsiveColumns(breakpoints)

    const itemData = [
        { img: Image1, title: 'Legends Ultimate', href: 'https://atgames.net/arcades', rows: 1, cols: columns / 5 },
        { img: Image2, title: 'ArcadeNet', href: 'https://atgames.net/arcadenet', rows: 1, cols: columns / 5 },
        { img: Image4, title: 'Atgames.us', href: 'https://www.atgames.us', rows: 1, cols: columns / 5 },
        { img: Image7, title: 'Blash', href: 'https://blast.atgames.net/', rows: 1, cols: columns / 5 },
        { img: Image9, title: 'AtGames Digital Services', href: 'https://www.atgames.net/ads', rows: 1, cols: columns / 5 },
        { img: Image5, title: 'About Us', href: '/about', rows: 1, cols: Math.round(columns) },
    ]

    return (
        <div className="container mx-auto mt-12">
            <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-${columns} gap-4`}>
                {itemData.filter(item => item.img !== Image5).map((item) => (
                    <Link key={item.img} to={item.href} className="block max-h-96 overflow-hidden rounded-lg shadow-lg">
                        <img
                            {...srcset(item.img, 150, 200, item.rows, item.cols)}
                            alt={item.title}
                            loading="lazy"
                            className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105"
                        />
                    </Link>
                ))}
            </div>
            <div className="flex justify-center">
                <Link to="/about" className="block w-1/2 max-h-96 overflow-hidden rounded-lg mt-4 mb-4 flex justify-center">
                    <img
                        {...srcset(Image5, 150, 200, 1, columns)}
                        alt="About Us"
                        loading="lazy"
                        className="w-full h-full object-cover transition-transform duration-300 ease-in-out transform hover:scale-105 shadow-lg"
                    />
                </Link>
            </div>
        </div>
    )
}

export default HomeImageList