import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { ArrowBack } from '@mui/icons-material';

class Snake {
    x!: number;
    y!: number;
    xSpeed: number;
    ySpeed: number;
    total: number;
    tail: { x: number; y: number }[];
    scale: number;
    context: CanvasRenderingContext2D;
    lastDirection: string;

    constructor(scale: number, context: CanvasRenderingContext2D) {
        this.x = 0;
        this.y = 0;
        this.xSpeed = scale;
        this.ySpeed = 0;
        this.total = 0;
        this.tail = [];
        this.scale = scale;
        this.context = context;
        this.lastDirection = 'Right';
    }

    draw() {
        this.context.fillStyle = "#000";
        for (let i = 0; i < this.tail.length; i++) {
            this.context.fillRect(this.tail[i].x, this.tail[i].y, this.scale, this.scale);
        }
        this.context.fillRect(this.x, this.y, this.scale, this.scale);
    }

    update() {
        for (let i = this.total - 1; i > 0; i--) {
            this.tail[i] = { ...this.tail[i - 1] };
        }
        if (this.total) {
            this.tail[0] = { x: this.x, y: this.y };
        }
        this.x += this.xSpeed;
        this.y += this.ySpeed;

        const canvasWidth = this.context.canvas.width;
        const canvasHeight = this.context.canvas.height;

        // Wrap around edges
        if (this.x >= canvasWidth) this.x = 0;
        if (this.y >= canvasHeight) this.y = 0;
        if (this.x < 0) this.x = canvasWidth - this.scale;
        if (this.y < 0) this.y = canvasHeight - this.scale;
    }

    changeDirection(direction: string) {
        if ((direction === 'Up' && this.lastDirection === 'Down') ||
            (direction === 'Down' && this.lastDirection === 'Up') ||
            (direction === 'Left' && this.lastDirection === 'Right') ||
            (direction === 'Right' && this.lastDirection === 'Left')) {
            return;
        }
        switch (direction) {
            case 'Up':
                this.xSpeed = 0;
                this.ySpeed = -this.scale;
                break;
            case 'Down':
                this.xSpeed = 0;
                this.ySpeed = this.scale;
                break;
            case 'Left':
                this.xSpeed = -this.scale;
                this.ySpeed = 0;
                break;
            case 'Right':
                this.xSpeed = this.scale;
                this.ySpeed = 0;
                break;
        }
        this.lastDirection = direction;
    }

    eat(fruit: Fruit) {
        return this.x === fruit.x && this.y === fruit.y;
    }

    checkCollision(setScore: React.Dispatch<React.SetStateAction<number>>) {
        for (let i = 0; i < this.tail.length; i++) {
            if (this.x === this.tail[i].x && this.y === this.tail[i].y) {
                this.total = 0;
                this.tail = [];
                setScore(0);
            }
        }
    }
}

class Fruit {
    x!: number;
    y!: number;
    scale: number;
    context: CanvasRenderingContext2D;
    rows: number;
    columns: number;

    constructor(scale: number, context: CanvasRenderingContext2D, rows: number, columns: number) {
        this.scale = scale;
        this.context = context;
        this.rows = rows;
        this.columns = columns;
        this.pickLocation(new Snake(scale, context));
    }

    pickLocation(snake: Snake) {
        let newLocation: { x: number; y: number };
        interface Location {
            x: number;
            y: number;
        }

        const isLocationOnSnake = (location: Location) => {
            return snake.tail.some((segment: Location) => segment.x === location.x && segment.y === location.y) ||
                (snake.x === location.x && snake.y === location.y);
        };

        do {
            newLocation = {
                x: Math.floor(Math.random() * this.rows) * this.scale,
                y: Math.floor(Math.random() * this.columns) * this.scale
            };
        } while (isLocationOnSnake(newLocation));
        this.x = newLocation.x;
        this.y = newLocation.y;
    }

    draw() {
        this.context.fillStyle = "rgb(153 27 27)";
        this.context.fillRect(this.x, this.y, this.scale, this.scale);
    }
}

export default function NotFound() {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [score, setScore] = useState(0);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;
        const scale = 20;
        const rows = canvas.width / scale;
        const columns = canvas.height / scale;

        const snake = new Snake(scale, context);
        const fruit = new Fruit(scale, context, rows, columns);

        let animationFrameId: number;
        let lastRenderTime = 0;
        const SNAKE_SPEED = 10;

        function setGridPattern() {
            if (!context) return;
            if (canvas) {
                for (let x = 0; x < canvas.width; x += scale) {
                    for (let y = 0; y < canvas.height; y += scale) {
                        context.strokeStyle = "#000";
                        context.lineWidth = 0.1;
                        context.strokeRect(x, y, scale, scale);
                    }
                }
            }
        }

        function gameLoop(currentTime: number) {
            if (!canvas || !context) return;
            const secondsSinceLastRender = (currentTime - lastRenderTime) / 1000;
            if (secondsSinceLastRender < 1 / SNAKE_SPEED) {
                animationFrameId = requestAnimationFrame(gameLoop);
                return;
            }
            lastRenderTime = currentTime;

            context.clearRect(0, 0, canvas.width, canvas.height);
            setGridPattern();
            fruit.draw();
            snake.update();
            snake.draw();

            if (snake.eat(fruit)) {
                snake.total++;
                fruit.pickLocation(snake);
                setScore(score => score + 1);
            }

            snake.checkCollision(setScore);

            animationFrameId = requestAnimationFrame(gameLoop);
        }

        animationFrameId = requestAnimationFrame(gameLoop);

        function handleKeyDown(e: KeyboardEvent) {
            const direction = e.key.replace('Arrow', '');
            snake.changeDirection(direction);
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.cancelAnimationFrame(animationFrameId);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="h-full overflow-auto">
            <div className="flex flex-col items-center justify-center h-screen text-white mb-16">
                <div className="h-auto w-full sm:w-3/4 lg:w-1/2 mx-auto mt-5 p-4 sm:p-6 lg:p-8 bg-[#bada55] bg-opacity-70 overflow-auto flex flex-col items-center rounded-lg shadow-lg backdrop-blur-sm">
                    <h1 className="text-6xl font-mono mb-4">404</h1>
                    <p className="text-xl mb-4">The page you’re looking for doesn’t exist.</p>
                    <Link to="/" className="bg-[#bada55] hover:bg-green-900 hover:text-white text-black font-bold py-2 px-4 rounded mb-4 border-2 border-black border-solid">
                        <ArrowBack /> Back Home
                    </Link>
                    <div className="flex flex-col items-center">
                        <p className="text-lg mb-2">Score: {score}</p>
                        <canvas ref={canvasRef} width="400" height="400" className="border-2 border-black border-solid"></canvas>
                    </div>
                </div>
            </div>
        </div>
    );
}
