import { Box, Divider, List, Typography } from '@mui/material'
import { atgamesLogoLongBlack, map } from '../../assets'
import WhiteList from '../../components/WhiteList'

const MarketingManager = () => {
    return (
        <Box sx={{ height: "100%", overflow: 'auto' }}>
            <Box
                sx={{
                    height: 'auto',
                    width: { xs: '90%', md: '50%' },
                    justifyContent: 'center',
                    backgroundColor: 'rgba(186, 218, 85, 0.7)',
                    margin: 'auto',
                    mt: 5,
                    overflow: 'auto',
                    mb: 16,
                }}
                className={'rounded-lg shadow-lg backdrop-blur-sm'}
            >
                <Box
                    component="img"
                    src={map} alt="atgamesLogo"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        width: '100%',
                        height: 'auto',
                        position: 'relative',
                        mb: 2
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={atgamesLogoLongBlack} className='h-10 w-auto' alt="AtGames Logo" />
                </Box>
                <Typography variant='h1' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 24, md: 32 } }}>
                    Marketing Manager
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    Location: El Segundo, California, United States
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    About Us
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    AtGames (www.atgames.net) is an international video game products company that combines over a decade of experience with innovative ideas and groundbreaking streaming technology.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Job Description
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    AtGames Interactive, a leader in innovative consumer-oriented interactive entertainment products, is looking for a passionate Marketing Manager for our marketing activities both direct to consumer and in support of our third-party retailer partners. We are seeking an individual who has a background and interest in selling games/electronic products to with strong attention to detail and a high degree of organization to support a busy and successful sales & marketing operations team. The successful candidate has knowledge of advertising and marketing best practices, as well as strong writing and analytical skills. Previous experience as a marketing manager, coordinator, specialist or assistant required.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Responsibilities
                </Typography>
                <List sx={{ mx: 4 }}>
                    <WhiteList text="Working with the executive team, and marketing leads to execute the marketing strategy for the business." />
                    <WhiteList text="Overseeing marketing campaigns and activities." />
                    <WhiteList text="Track effectiveness of marketing campaigns and reporting findings to the executive team." />
                    <WhiteList text="Website maintenance." />
                    <WhiteList text="Social media management - writing and delivering content and social media plans." />
                    <WhiteList text="Negotiating and liaising with third-party marketing agencies." />
                    <WhiteList text="Manage design and production of promotional materials and digital assets." />
                    <WhiteList text="Oversee the company’s attendance at events, such as trade shows, conferences." />
                </List>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Qualifications and Skills
                </Typography>
                <List sx={{ mx: 4 }}>
                    <WhiteList text="Bachelor’s degree in marketing, business, or related field" />
                    <WhiteList text="Excellent written and verbal communication skills" />
                    <WhiteList text="Proven experience developing marketing plans and campaigns" />
                    <WhiteList text="Strong project management, multitasking, and decision-making skills" />
                    <WhiteList text="Metrics-driven marketing mind with an eye for creativity" />
                    <WhiteList text="Experience with marketing platforms: Facebook, Google Ads, etc." />
                    <WhiteList text="Experience or exposure to public relations, press release writing, media outreach." />
                    <WhiteList text="1 or more years of experience in national sales or marketing for games/electronics" />
                    <WhiteList text="Positive and enthusiastic attitude and assertive communicator" />
                    <WhiteList text="Strong attention to detail. Also, ability to retain large amounts of information" />
                    <WhiteList text="Analytical with strong organizational skills" />
                    <WhiteList text="Ability to adapt to a moderately dynamic environment that may occasionally grow chaotic" />
                    <WhiteList text="Ethical and professional" />
                    <WhiteList text="Articulate & professional phone and writing skills" />
                    <WhiteList text="Strong knowledge of Excel, Word, PowerPoint, and Outlook" />
                    <WhiteList text="Proactive with the ability to complete tasks without supervision" />
                    <WhiteList text="Team player" />
                </List>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    AtGames has a unique culture of being an established company with exciting new initiatives that give us a highly dynamic startup vibe. Our team is flexible and energetic, with a strong work ethic and a passion for gaming and for our products. You’ll fit in very well if you appreciate being given an opportunity to give feedback and help grow exciting new digital products, as well as maintain existing ones, beyond the scope of your role.
                </Typography>
                <Typography variant='h2' sx={{ color: 'white', fontWeight: 700, mt: 2, mx: 4, fontSize: { xs: 20, md: 26 } }}>
                    Job Type
                </Typography>
                <Typography sx={{ color: 'white', fontWeight: 500, mx: 4 }}>
                    Full-time
                </Typography>
                <Divider sx={{ margin: 4 }} color='white' />
            </Box>
        </Box>
    )
}

export default MarketingManager