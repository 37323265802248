import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => {
    return (
        <Box sx={{ textAlign: 'center', padding: '1rem', backgroundColor: "rgba(0,0,0,0.8)", color: '#fff', position: 'fixed', bottom: 0, width: '100%', boxShadow: '0 -2px 10px rgba(0,0,0,0.1)' }} className={'shadow-lg backdrop-blur-sm'}>
            <Typography variant="body2">
                Copyright &copy; AtGames Cloud Holdings {new Date().getFullYear()}. All rights reserved
            </Typography>
        </Box>
    );
};

export default Footer;