import '../App.css'

const About = () => {
  return (
    <div className="h-full overflow-auto">
      <div className="h-auto w-full sm:w-3/4 lg:w-1/2 mx-auto mt-5 p-4 sm:p-6 lg:p-8 bg-[#bada55] bg-opacity-70 overflow-auto flex flex-col items-center rounded-lg shadow-lg backdrop-blur-sm z-10">
        <h1 className="font-bold text-2xl sm:text-3xl lg:text-4xl tracking-widest uppercase font-mono text-white mb-2">About us</h1>
        <p className="mt-1 text-sm sm:text-base lg:text-lg text-white">
          AtGames®, founded in 2001, is a veteran player in digital media and information<br />
          technology with a unique focus on interactive entertainment.<br />
          Over the years, our achievements have spanned retail products, digital distribution,<br />
          streaming technology, and more.<br />
          With locations in Los Angeles, Taipei, and Shenzhen, our globe-spanning office branches<br />
          synergize and evolve to produce products and services that stand out from the rest.
          <br /><br />
          Legends Arcade Family: <a href="https://www.atgames.net/arcades/contact/" className='aLink'>https://www.atgames.net/arcades/contact/</a><br />

          Flashback and Blast Products: <a href="mailto:info@atgames.net" className='aLink'>info@atgames.net</a><br />

          Media/Press Inquiries: <a href="mailto:pr@atgames.net" className='aLink'>pr@atgames.net</a><br />
        </p>
      </div>
    </div>
  )
}

export default About