import { Box } from '@mui/material'
import { atgamesLogoLong } from '../assets'
import HomeImageList from '../components/HomeImageList'

const Home = () => {
  return (
    <Box className="h-full overflow-auto p-4">
      <img src={atgamesLogoLong} alt="AtGames Logo" className="h-12 mx-auto my-4" />
      <HomeImageList />
    </Box>
  )
}

export default Home